import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, SearchBar, Pagination, LoadingAnimation, MessageBar } from 'app/components';
import {
  integrationsDataSelector,
  integrationsLoadingSelector,
  integrationsErrorsSelector,
  merchantIntegrationsDataSelector,
  merchantIntegrationsLoadingSelector,
  merchantIntegrationsErrorsSelector
} from 'app/store/selectors/integration';
import { getIntegrations, getMerchantIntegrations } from 'app/store/actions/integration';
import { currentUserSelector } from 'app/store/selectors/user';
import { isMerchant } from 'app/permissions';
import PluginCard from '../PluginCard';
import './index.scss';

const PublicIntegrations = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const [currentCategory, setCurrentCategory] = useState(null);

  const currentUser = useSelector(currentUserSelector);
  const isMerchantUser = isMerchant();
  const integrationsData = useSelector(integrationsDataSelector);
  const loading = useSelector(integrationsLoadingSelector);
  const errorsFound = useSelector(integrationsErrorsSelector);
  const merchantIntegrationsData = useSelector(merchantIntegrationsDataSelector);
  const merchantLoading = useSelector(merchantIntegrationsLoadingSelector);
  const merchantErrorsFound = useSelector(merchantIntegrationsErrorsSelector);

  const categories = [
    {
      label: 'All Plugins',
      value: null,
    },
    {
      label: 'Vendors',
      value: 'Vendor',
    },
    {
      label: 'Merchants',
      value: 'Merchant',
    },
  ];

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(getIntegrations({
        category: currentCategory,
        searchString,
        currentPage,
        pageSize,
        isPublished: true,
        canBeOverriden: true,
      }));
      
      if (isMerchantUser) {
        dispatch(getMerchantIntegrations({
          merchantId: currentUser.attributes?.entityId?.[0],
          category: currentCategory,
          searchString,
          currentPage,
          pageSize,
          isPublished: true,
        }));
      }
    }
  }, [currentCategory, searchString, currentPage, pageSize, dispatch, isMerchantUser]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  };

  const updatedIntegrationsData = useMemo(() => {
    if (!integrationsData) {
      return { plugins: [] };
    }
    
    if (!isMerchantUser) {
      return integrationsData;
    }

    if (!merchantIntegrationsData) {
      return { plugins: [] };
    }

    return {
      ...integrationsData,
      plugins: integrationsData.plugins?.map((plugin) => {
        const alreadyConnected = merchantIntegrationsData.plugins?.some(
          (merchantPlugin) => merchantPlugin.pluginId === plugin.id
        );
        return { ...plugin, alreadyConnected };
      }) || [],
    };
  }, [integrationsData, merchantIntegrationsData, isMerchantUser]);

  return (
    <div className="public-integrations">
      {(loading || merchantLoading) && <LoadingAnimation />}
      <SearchBar
        searchPlaceholderText="Search for a Plugin"
        onSearchStringUpdated={onSearchStringUpdated}
        debounceDelay={500}
      />
      <div className="public-integrations-container">
        <Card className="content-categories-card">
          <div className="content-categories">
            <div className="content-categories-list">
              {categories.map((category) => (
                <div
                  key={category.value}
                  className={`content-category ${category.value === currentCategory ? 'active' : ''}`}
                  onClick={() => setCurrentCategory(category.value)}
                >
                  {category.label}
                </div>
              ))}
            </div>
          </div>
        </Card>
        <div className="integration-cards-container">
          <div className="public-integrations-header">
            Plugins
          </div>
          <div className="public-integrations-content">

            {!errorsFound && !merchantErrorsFound && updatedIntegrationsData && (
              <>
                {(updatedIntegrationsData.plugins?.length === 0) && !loading && !merchantLoading ? (
                  <div className="no-integrations-found">
                    No Plugins Found
                  </div>
                ) : (
                  <div className="content-list">
                    {updatedIntegrationsData.plugins?.map(plugin => <PluginCard key={plugin.id} plugin={plugin} isMerchantUser={isMerchantUser} />)}
                  </div>
                )}
              </>
            )}
            {(errorsFound || merchantErrorsFound) && (
              <MessageBar color="yellow">
                An error occurred while loading Plugin data
              </MessageBar>
            )}
          </div>
          <Pagination
            totalItems={updatedIntegrationsData.plugins?.length || 0}
            hideBelow={30}
          />
        </div>
      </div>
    </div>
  );
}

export default PublicIntegrations;
