import { createSelector } from 'reselect';

const merchantSelector = state => state.merchant;

export const merchantsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.data);
export const merchantsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.loading);
export const merchantsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchants.error);

export const merchantDetailsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.data);
export const merchantDetailsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.loading);
export const merchantDetailsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchant.error);

export const merchantTemplatesSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplates?.data);
export const merchantTemplatesLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplates.loading);
export const merchantTemplatesErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplates.error);

export const templateSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplate.data);
export const templateLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplate.loading);
export const templateErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplate.error);

export const fileSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantTemplate?.renderData?.fileInfo);

export const configurationSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantConfiguration?.data);
export const configurationLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantConfiguration?.loading);

export const searchMerchantsSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchMerchants?.data);
export const searchMerchantsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchMerchants?.loading);
export const searchMerchantsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchMerchants?.error);

export const searchViewAsMerchantSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchViewAsMerchants?.data);
export const searchViewAsMerchantLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchViewAsMerchants?.loading);
export const searchViewAsMerchantErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.searchViewAsMerchants?.error);

export const bulkGetMerchantsSelector = createSelector([merchantSelector], merchantStore => merchantStore.bulkMerchants?.data);
export const bulkGetMerchantsLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.bulkMerchants?.loading);
export const bulkGetMerchantsErrorsSelector = createSelector([merchantSelector], merchantStore => merchantStore.bulkMerchants?.error);

export const schemaSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantSchema?.data);

//
// Config and Schema constants...
//
// Those string in constants are defined in schema and configuration.
// If schema or configuration changes, those constants should be updated.
// We need them here hardcoded in order to properly render UI.
//
export const SchemaTypes = {
    BOOLEAN: 'boolean',
    OBJECT: 'object',
    STRING: 'string',
    INTEGER: 'integer',
    DROPDOWN: 'dropdown'
}

const parseSectionSettings = (section, schema) => {
    const sectionPropertyKeys = section.properties;
    const schemaProperties = schema.properties;

    // return empty if we have no section properties...
    if (!sectionPropertyKeys) {
        return [];
    }

    // get schema properties by sectionPropertyKeys...
    const settings = [];
    sectionPropertyKeys.forEach(key => {
        const schemaProperty = schemaProperties[key];
        if (!schemaProperty) {
            return;
        }

        // parse schemaProperty to settings item...
        let setting = {
            key: key,
            type: schemaProperty.type,
            description: schemaProperty.description
        }

        settings.push(setting);
    });

    return settings;
}

const parseSubSections = (section, schema) => {
    if (!section?.subSections?.length) {
        return [];
    }

    const subSections = [];
    section.subSections.forEach(subSection => {
        let s = {
            title: subSection.title,
            key: subSection.title,
            settings: parseSectionSettings(subSection, schema),
            type: subSection.type
        }
        subSections.push(s);
    });

    return subSections;
}

export const settingsSelector = createSelector([schemaSelector], schemaData => {
    const settings = [];

    // we need MerchantsConfiguration schema, and "x-sections" to render UI...
    const schema = schemaData?.jsonSchema;
    if (!schema || !schema?.['x-sections']) {
        return [];
    }

    // sort sections by order, and iterate...
    const schemaSections = schema['x-sections'].sort((a, b) => a.order - b.order);
    schemaSections.forEach(section => {
        let s = {
            title: section.title,
            key: section.title,
            settings: parseSectionSettings(section, schema),
            subSections: parseSubSections(section, schema),
            type: section.type
        }
        settings.push(s);
    });

    // if some UI settings are not found in schema itself, we will remove them, in order not to render them...
    const cleanSettings =(data) => {
        return data
            .map(section => {
                const filteredSubSections = section.subSections
                    .map(subSection => ({
                        ...subSection,
                        settings: subSection.settings.filter(setting => Object.keys(setting).length > 0)
                    }))
                    .filter(subSection => subSection.settings.length > 0);
    
                return {
                    ...section,
                    settings: section.settings.filter(setting => Object.keys(setting).length > 0),
                    subSections: filteredSubSections
                };
            })
            .filter(section => section.settings.length > 0 || section.subSections.length > 0);
    }
    
    return cleanSettings(settings);
});

export const schemaLoadingSelector = createSelector([merchantSelector], merchantStore => merchantStore.merchantSchema?.loading);

export const merchantSelectedPackingSlipSelector = createSelector([configurationSelector], config => config?.data?.BrandingSettings?.PackingSlipTemplateId);
export const merchantSelectedReturnAddressType = createSelector([configurationSelector], config => config?.data?.CustomReturnAddress?.ReturnAddressType)

// this is a sample data for rendering some of templates...
// this data has to be provided in request payload, when rendering any template...
// https://gooten.atlassian.net/browse/OMG-107
export const sampleDataTemplateSelector = () => {
    return {
        "order": {
            "id": "664d04077826075558ab4244",
            "shippingAddress": {
                "firstName": "John",
                "lastName": "Doe",
                "line1": "123 Main St",
                "line2": "Apt 4B",
                "line3": null,
                "city": "Anytown",
                "state": "CA",
                "zip": "90210",
                "countryCode": "US",
                "phone": "555-1234",
                "email": "john.doe@example.com",
                "location": {
                    "longitude": -118.416253,
                    "latitude": 34.103131
                }
            },
            "billingAddress": {
                "firstName": "Jane",
                "lastName": "Smith",
                "line1": "456 Oak St",
                "line2": "",
                "line3": null,
                "city": "Othertown",
                "state": "NY",
                "zip": "10001",
                "countryCode": "US",
                "phone": "555-5678",
                "email": "jane.smith@example.com",
                "location": null
            },
            "externalId": "ORDER123456",
            "safeId": "UniqueJaimeTest",
            "languageCode": "EN",
            "customerTotal": {
                "currency": "USD",
                "amount": 200.5
            },
            "status": "fulfillmenterror",
            "merchantId": "46bae16f-3ccd-4afa-90d2-706551acbed7",
            "merchantName": "Sales channel 1",
            "customerShippingMethod": 1,
            "isTest": true,
            "createdAt": "2024-05-21T20:28:55.518Z",
            "updatedAt": "2024-05-21T20:28:59.274Z",
            "cancelledAt": null,
            "items": [
                {
                    "id": "664d04077826075558ab4245",
                    "sku": "Scrunchie-Jersey",
                    "quantity": 2,
                    "status": "open",
                    "createdAt": "2024-05-21T20:28:55.518Z",
                    "updatedAt": "2024-05-21T20:28:56.761Z",
                    "processedAt": null,
                    "cancelledAt": null,
                    "images": [
                        {
                            "url": "https://www.pixelstalk.net/wp-content/uploads/2016/07/Free-Amazing-Background-Images-Nature.jpg",
                            "manipCommand": "",
                            "thumbnailUrl": "https://www.pixelstalk.net/wp-content/uploads/2016/07/Free-Amazing-Background-Images-Nature.jpg",
                            "thumbnailFileId": null,
                            "area": "Default",
                            "printMethod": "DTG",
                            "ourUrl": "https://gtndevfilessa.blob.core.windows.net/private/order-image/664d04077826075558ab4244/664d04077826075558ab4245/3f4a8cdd-5775-4253-8cf5-c33496292119?sv\u003d2023-01-03\u0026st\u003d2024-05-21T20%3A28%3A56Z\u0026se\u003d2024-05-21T21%3A28%3A56Z\u0026sr\u003db\u0026sp\u003dr\u0026sig\u003dPwNi%2BPu2j7SaQdPg9GrB5iRS%2BuBzRRLFpBsWAAr7Zws%3D",
                            "ourFileId": "3f4a8cdd-5775-4253-8cf5-c33496292119",
                            "generatedImageUrl": null,
                            "generatedFileId": null,
                            "vendorFileId": null
                        }
                    ],
                    "options": [
                        {
                            "name": "Size",
                            "value": "Medium"
                        },
                        {
                            "name": "Color",
                            "value": "White"
                        }
                    ],
                    "attributes": null,
                    "customerSubtotal": {
                        "currency": "USD",
                        "amount": 40.0
                    },
                    "customerPrice": {
                        "currency": "USD",
                        "amount": 20.0
                    },
                    "customerShippingPrice": {
                        "currency": "USD",
                        "amount": 5.0
                    },
                    "merchantPrice": {
                        "currency": "USD",
                        "amount": 10.9000
                    },
                    "merchantShippingPrice": null,
                    "clientAttributes": {
                        "occasion": "Birthday gift",
                        "urgency": "high"
                    },
                    "meta": {
                        "designId": "D123456",
                        "artist": "ArtistName"
                    },
                    "isSample": true,
                    "externalId": "PROD7890123",
                    "skipImgManip": true
                }
            ],
            "meta": {
                "customerNote": "Please deliver between 9 AM and 5 PM.",
                "giftWrap": "true",
                "promocode": "SUMMER2024",
                "packing_slip": "ec505671-d9b2-44ba-917a-8f5e937443e4"
            }
        },
        "merchant": {
            "id": "46bae16f-3ccd-4afa-90d2-706551acbed7",
            "shortId": "01310001",
            "children": [],
            "parent": null,
            "legalBusinessName": "Sales Channel 1 ",
            "email": "APItest@mail.com",
            "name": "Sales channel 1",
            "url": null,
            "address1": "Main st. 42",
            "address2": "",
            "city": "New York",
            "state": "New York",
            "country": "US",
            "zipCode": "10001",
            "phoneNumber": null,
            "enabled": true,
            "isParent": false,
            "skipImgManip": null,
            "customShipping": null,
            "isMerchantVasFlexible": true
        },
        "shipment": {
            "id": "ef0978f4-a0b6-44cf-9312-0af835d02812",
            "merchantId": "46bae16f-3ccd-4afa-90d2-706551acbed7",
            "merchantName": null,
            "vendorId": "aeec84ef-98bf-470d-9df6-99526e0a13b4",
            "facilityId": "F01250005",
            "vendorName": null,
            "orderId": "664d04077826075558ab4244",
            "shortId": "S07900324",
            "orderExternalId": null,
            "safeId": null,
            "customerId": null,
            "customerShippingAddressId": 0,
            "merchantPaymentId": null,
            "shippingCountryCodeISO": 0,
            "shippingCountryCode": null,
            "shipmentStatus": 1,
            "currencyCode": "USD",
            "languageCodeISO": 0,
            "languageCode": null,
            "transactionalCostModel": null,
            "createdDate": "2024-05-21T20:28:56.938Z",
            "orderCreatedDate": "0001-01-01T00:00:00Z",
            "lastModifiedDate": "0001-01-01T00:00:00",
            "merchantOrderTotal": 21.8,
            "merchantPackageTotal": 21.8,
            "merchantTaxTotal": 0.0,
            "merchantShippingTotal": 0.0,
            "packageTotal": 18.0,
            "taxTotal": 0.0,
            "shippingTotal": 0.0,
            "total": 18.0,
            "costTotal": 18.0,
            "costTaxTotal": 0.0,
            "costShippingTotal": 0.0,
            "costShippingTaxTotal": 0.0,
            "packages": [
                {
                    "id": "b6d9e60c-8afc-4679-a7a2-f6f93274d454",
                    "shortId": "P08900273",
                    "shipmentId": "ef0978f4-a0b6-44cf-9312-0af835d02812",
                    "carrierName": "USPS",
                    "carrierMethodName": "Priority",
                    "shippedDate": "0001-01-01T00:00:00Z",
                    "fulfilledDate": "0001-01-01T00:00:00Z",
                    "carrierTrackingNumber": "9405500110380043443091",
                    "carrierLink": "https://track.easypost.com/djE6dHJrXzg1ZWQ2NmQ5MWIwNDQxMTQ4NTI4YWQxMWZlODNmZThh",
                    "carrierAccount": true,
                    "orderItemIDs": [
                        "664d04077826075558ab4245"
                    ]
                }
            ],
            "orderItemIDs": [
                "664d04077826075558ab4245"
            ],
            "orderItems": [
                {
                    "id": "664d04077826075558ab4245",
                    "sku": "Scrunchie-Jersey",
                    "vendorSku": "SC0001U",
                    "variantId": "144029",
                    "vendorProductId": "aa7a8f97-486c-4920-a6f8-493ee4dff7ba",
                    "quantity": 2,
                    "merchantPrice": {
                        "currency": "USD",
                        "amount": 10.9,
                        "tax": 0.0
                    },
                    "price": {
                        "currency": "USD",
                        "amount": 9.0,
                        "tax": 0.0
                    }
                }
            ]
        },
        "vendor": {
            "vendorId": "aeec84ef-98bf-470d-9df6-99526e0a13b4",
            "name": "ORTest Data Vendor 3",
            "facilities": [
                {
                    "id": "F01250005",
                    "name": "Miami",
                    "address": {
                        "line1": "16085 NW 52nd Ave",
                        "city": "Miami",
                        "state": "FL",
                        "zip": "33014",
                        "countryCode": "US",
                        "location": null,
                        "line2": ""
                    },
                    "shippingMethods": []
                }
            ],
            "address": {
                "line1": "16085 NW 52nd Ave",
                "city": "Florida",
                "state": "Miami",
                "zip": "33014",
                "countryCode": "US",
                "location": null,
                "line2": ""
            }
        }
    }
}