import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { subscriberSelector } from 'app/store/selectors/user';
import { searchMerchantsLoadingSelector, searchMerchantsErrorsSelector, searchMerchantsSelector } from 'app/store/selectors/merchant';
import { searchMerchants, reset } from 'app/store/actions/merchant';
import { Link, Card, Button, Table, TableHeader, Pagination, LoadingAnimation, StatusChip, SearchBar } from 'app/components';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { isMerchantParent, isSuperAdmin, usePermission } from 'app/permissions';
import InviteMerchantModal from './InviteMerchantModal';
import moment from 'moment';
import './index.scss';

const MerchantsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchMerchantsData = useSelector(searchMerchantsSelector);
  const searchMerchantsLoading = useSelector(searchMerchantsLoadingSelector);
  const searchMerchantsErrors = useSelector(searchMerchantsErrorsSelector);
  const subscriber = useSelector(subscriberSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const sortBy = searchParams.get('sortBy') || 'shortId';
  const sortDir = searchParams.get('sortDir') || 'desc';
  const statusFilter = searchParams.get('status') || null;

  // get user permissions
  const canCreateMerchants = usePermission('merchant', 'create') && isMerchantParent() || isSuperAdmin();

  const [showInviteModal, setShowInviteModal] = useState(false);

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(searchMerchants({
        searchString,
        currentPage,
        pageSize,
        sortBy,
        sortDir
      }));
    }
  }, [searchString, statusFilter, currentPage, pageSize, sortBy, sortDir, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    }
  }, []);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  };

  const merchantType = (merchant) => {
    if (merchant.parentId) {
      return 'Child Merchant';
    } else if (merchant.isParent) {
      return 'Parent Merchant';
    } else {
      return 'Merchant';
    }
  }

  return (
    <div className="merchants-view">
      {searchMerchantsLoading && <LoadingAnimation />}
      {(searchMerchantsData || searchMerchantsErrors) && (
        <Card>
          <div className="merchants-header">
            Merchants
            <div className="merchant-buttons">
              {subscriber?.entity === 'parent-merchant' && (
                <Button
                  variant="secondary"
                  size="medium"
                  label={"Invite Merchant"}
                  disabled={searchMerchantsErrors}
                  onClick={() => setShowInviteModal(true)}
                />
              )}
              {canCreateMerchants && (
                <Button
                  variant="primary"
                  size="medium"
                  label={"Create Merchant"}
                  disabled={searchMerchantsErrors}
                  onClick={() => {
                    navigate(`/admin/merchants/create-merchant`);
                  }}
                />
              )}
            </div>
          </div>
          <div className="search-bar-and-filters">
            <SearchBar
              searchPlaceholderText="Search for a Merchant"
              onSearchStringUpdated={onSearchStringUpdated}
              debounceDelay={500}
              disabled={searchMerchantsErrors}
            />
          </div>
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'shortId', label: 'Merchant ID', orderable: searchMerchantsErrors ? false : true },
                { id: 'name', label: 'Merchant Name', orderable: searchMerchantsErrors ? false : true },
                { id: 'createdAt', label: 'Created Date', orderable: searchMerchantsErrors ? false : true },
                { id: 'merchantType', label: 'Merchant Type' },
                { id: 'status', label: 'Status', align: 'center' },
              ]}
            />
            <tbody className="table-body">
              {searchMerchantsData && searchMerchantsData.merchants.map((merchant) => (
                <tr className="merchant-row" key={merchant.id}>
                  <td>
                    <Link
                      label={merchant.shortId || merchant.id}
                      url={`/admin/merchants/${merchant.id || merchant.shortId}`}
                      onClick={() => {
                        navigate(`/admin/merchants/${merchant.id || merchant.shortId}`)
                      }}
                    />
                  </td>
                  <td>{merchant.name}</td>
                  <td>{moment(merchant.meta?.createdAt).format('MMM DD, YYYY')}</td>
                  <td>{merchantType(merchant)}</td>
                  <td className="merchant-status-chip"><StatusChip status={merchant.enabled ? 'Active' : 'Disabled'} /></td>
                </tr>
              ))}
              {searchMerchantsData?.merchants.length === 0 && !searchMerchantsErrors && !searchMerchantsLoading && (
                <tr>
                  <td colSpan="6" className="error-message">No Results Found</td>
                </tr>
              )}
              {searchMerchantsErrors && !searchMerchantsLoading && (
                <tr>
                  <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching merchant data</td>
                </tr>
              )}
            </tbody>
          </Table>
          {searchMerchantsData && (
            <Pagination
              totalItems={searchMerchantsData?.total || 0}
              hideBelow={30}
            />
          )}
        </Card>
      )}
      {showInviteModal && (
        <InviteMerchantModal
          showInviteMerchantModal={setShowInviteModal}
          subscriberId={subscriber.id}
        />
      )}
    </div>
  )
}

export default MerchantsView;