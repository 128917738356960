import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string, array } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createIntegration, getIntegrationBasicInfo, updateIntegrationBasicInfo } from 'app/store/actions/integration';
import {
  integrationsLoadingSelector,
  integrationsErrorsSelector,
  integrationDetailsSelector,
  integrationDetailsLoadingSelector,
  integrationDetailsErrorsSelector
} from 'app/store/selectors/integration';
import PluginMarketplaceSettings from './PluginMarketplaceSettings';
import { getVendors } from 'app/store/actions/vendor';
import { vendorsDataSelector, vendorsLoadingSelector, vendorsErrorsSelector } from 'app/store/selectors/vendor';
import { ButtonIcon, Button, Card, LoadingAnimation, Checkbox, Dropdown, Input, MessageBar, MultiSelect } from 'app/components';
import { ArrowLeft } from 'react-bootstrap-icons';
import './index.scss';

const BasicDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pluginId } = useParams();
  const [selectedVendorFacilities, setSelectedVendorFacilities] = useState([]);

  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);
  const vendorsErrors = useSelector(vendorsErrorsSelector);
  const integrationsLoading = useSelector(integrationsLoadingSelector);
  const integrationsErrors = useSelector(integrationsErrorsSelector);
  const integrationDetails = useSelector(integrationDetailsSelector);
  const integrationDetailsLoading = useSelector(integrationDetailsLoadingSelector);
  const integrationDetailsErrors = useSelector(integrationDetailsErrorsSelector);

  const filteredVendors = vendorsData?.vendors
    .filter(obj => obj.name && obj.name.trim() !== "" && obj.facilities.length > 0)
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (pluginId) {
      dispatch(getIntegrationBasicInfo({ pluginId }));
    }
    dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
  }, []);

  useEffect(() => {
    if (pluginId && filteredVendors && integrationDetails?.basicInfo) {
      const vendor = filteredVendors.find((v) => v.id === integrationDetails.basicInfo.integratorId);
      if (vendor) {
        setSelectedVendorFacilities(vendor.facilities);
      }
    }
  }, [pluginId, filteredVendors, integrationDetails]);

  const preselectedVendor = pluginId ?
    filteredVendors?.find((vendor) => vendor.id === integrationDetails?.basicInfo?.integratorId) :
    null;

  const preselectedVendorFacilities = preselectedVendor ?
    preselectedVendor.facilities.filter((facility) =>
      integrationDetails?.basicInfo?.subIntegratorIds.includes(facility.id)
    ) :
    [];

  const integrationTypes = [{ "Name": "Merchant", "Value": "Merchant" }, { "Name": "Vendor", "Value": "Vendor" }];
  const audiences = [{ "Name": "Public", "Value": "Public" }, { "Name": "Private", "Value": "Private" }];

  const handleVendorChange = (e, setFieldValue, validateForm) => {
    const selectedVendor = filteredVendors.find((vendor) => vendor.id === e.target.value);
    const facilities = selectedVendor ? selectedVendor.facilities : [];
    setSelectedVendorFacilities(facilities);
    setFieldValue('vendorId', e.target.value);
    setFieldValue('vendorfacilities', []);
    setTimeout(() => {
      validateForm();
    }, 1);
  };

  return (
    <div className="basic-details">
      {(integrationsLoading || vendorsLoading || integrationDetailsLoading) && (
        <LoadingAnimation />
      )}
      {vendorsData?.vendors && (!pluginId || integrationDetails?.basicInfo) && (
        <Formik
          enableReinitialize
          initialValues={{
            integrationName: integrationDetails?.basicInfo?.name || '',
            integrationType: integrationDetails?.basicInfo?.type || '',
            vendorId: integrationDetails?.basicInfo?.integratorId || '',
            vendorfacilities: integrationDetails?.basicInfo?.subIntegratorIds || [],
            audience: integrationDetails?.basicInfo?.isPrivate ? 'Private' : 'Public',
            ownerEmail: integrationDetails?.basicInfo?.creatorEmail || '',
            canBeOverridden: integrationDetails?.basicInfo?.canBeOverriden || false,
            allowMerchantShippingAccountNumbers: integrationDetails?.basicInfo?.allowMerchantShippingAccountNumbers || false,
            pluginDescription: integrationDetails?.basicInfo?.pluginDescription || '',
            uploadedFile: null,
            imageUrl: integrationDetails?.basicInfo?.logoUrl || '',
          }}
          validationSchema={() =>
            object({
              integrationName: string().required('Plugin Name is required'),
              integrationType: string().required('Plugin Type is required'),
              vendorId: string().required('Vendor Name is required'),
              vendorfacilities: array().min(1, 'At least one Vendor Facility is required'),
              audience: string().required('Audience is required'),
              ownerEmail: string().email('Invalid email').required('Owner Email is required'),
              pluginDescription: string(),
              uploadedFile: object()
                .nullable()
                .test(
                  'min-resolution',
                  'Uploaded logo must be at least 100 x 100 pixels',
                  function (value) {
                    if (!value) return true;
                    const { width, height } = value;
                    return width >= 100 && height >= 100;
                  }
                ),
              imageUrl: string()
                .url('Invalid URL')
                .nullable(),
            })
          }

          onSubmit={async (values) => {
            const data = {
              name: values.integrationName,
              type: values.integrationType,
              integratorId: values.vendorId,
              subIntegratorIds: values.vendorfacilities,
              creatorEmail: values.ownerEmail,
              isPrivate: values.audience === 'Private',
              canBeOverriden: values.canBeOverridden,
              allowMerchantShippingAccountNumbers: values.allowMerchantShippingAccountNumbers,
              pluginDescription: values.pluginDescription,
              uploadedFile: values.uploadedFile,
              imageUrl: values.imageUrl,
            };
            if (pluginId) {
              dispatch(updateIntegrationBasicInfo({ data, pluginId, cb: () => navigate(`/admin/plugins/manage-plugins`) }));
            } else {
              dispatch(createIntegration({ data, cb: (pluginId) => navigate(`/admin/plugins/${pluginId}/0`) }));
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            validateForm,
            isSubmitting,
            submitCount,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className="integrations-header">
                <div className="integrations-title">
                  <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/plugins/manage-plugins')} />
                  {pluginId ? 'Edit Basic Settings' : 'Create Plugin'}
                </div>
                <div className="action-buttons">
                  <Button
                    label="Cancel"
                    variant="secondary"
                    size="medium"
                    onClick={() => {
                      navigate(`/admin/plugins/manage-plugins`);
                    }}
                  />
                  <Button
                    label={pluginId ? 'Save Changes' : 'Create Plugin'}
                    variant="primary"
                    size="medium"
                    disabled={integrationsLoading || vendorsErrors}
                    onClick={() => (isSubmitting ? null : handleSubmit())}
                  />
                </div>
              </Card>
              {integrationsErrors && (
                <MessageBar color="yellow" className="integrations-error">
                  An error occurred while fetching data
                </MessageBar>
              )}
              <Card className="merchant-information">
                <Card.Header hideDivider={integrationsErrors || vendorsErrors}>Basic Settings</Card.Header>
                {integrationDetailsErrors || vendorsErrors ? (
                  <MessageBar color="yellow">
                    An error occurred while fetching data
                  </MessageBar>
                ) : pluginId && (!preselectedVendor || preselectedVendorFacilities.length === 0) ? (
                  <MessageBar color="yellow">
                    An error occurred matching the vendor and facilities
                  </MessageBar>
                ) : (
                  <Card.Body>
                    <div className="field-container">
                      <Input
                        label="Plugin Name"
                        name="integrationName"
                        value={values.integrationName}
                        onChange={handleChange}
                        placeholder="Plugin Name"
                        errorMessage={submitCount > 0 && errors.integrationName}
                      />
                      <Dropdown
                        label="Plugin Type"
                        name="integrationType"
                        value={values.integrationType}
                        disabled={pluginId ? true : false}
                        onChange={handleChange}
                        options={integrationTypes.map((type) => ({ value: type.Value, label: type.Name }))}
                        errorMessage={submitCount > 0 && errors.integrationType}
                      />
                      <Dropdown
                        label="Vendor Name"
                        name="vendorId"
                        value={values.vendorId}
                        searchable={true}
                        disabled={pluginId ? true : false}
                        onChange={(e) => handleVendorChange(e, setFieldValue, validateForm)}
                        options={filteredVendors.map((vendor) => ({ value: vendor.id, label: vendor.name }))}
                        errorMessage={submitCount > 0 && errors.vendorId}
                      />
                      <MultiSelect
                        label="Vendor Facilities"
                        options={selectedVendorFacilities.map((facility) => ({
                          value: facility.id,
                          label: facility.name
                        }))}
                        value={values.vendorfacilities.map(id => ({
                          value: id,
                          label: selectedVendorFacilities.find(f => f.id === id)?.name || ''
                        }))}
                        onChange={(selected) => {
                          setFieldValue('vendorfacilities', selected.map(item => item.value));
                        }}
                        readonly={pluginId ? true : false}
                        errorMessage={submitCount > 0 && errors.vendorfacilities}
                      />
                      <Dropdown
                        label="Audience"
                        name="audience"
                        value={values.audience}
                        onChange={handleChange}
                        options={audiences.map((audience) => ({ value: audience.Value, label: audience.Name }))}
                        errorMessage={submitCount > 0 && errors.audience}
                      />
                      <Input
                        label="Owner Email"
                        name="ownerEmail"
                        value={values.ownerEmail}
                        onChange={handleChange}
                        placeholder="Owner Email"
                        errorMessage={submitCount > 0 && errors.ownerEmail}
                      />
                      <Checkbox
                        label="Can Be Overridden"
                        name="canBeOverridden"
                        size="medium"
                        checked={values.canBeOverridden}
                        onChange={() => setFieldValue('canBeOverridden', !values.canBeOverridden)}
                      />
                      <Checkbox
                        label="Allow Merchant Shipping Credentials"
                        name="allowMerchantShippingAccountNumbers"
                        size="medium"
                        checked={values.allowMerchantShippingAccountNumbers}
                        onChange={() => !pluginId && setFieldValue('allowMerchantShippingAccountNumbers', !values.allowMerchantShippingAccountNumbers)}
                        disabled={!!pluginId}
                      />
                    </div>
                  </Card.Body>
                )}
              </Card>
              <PluginMarketplaceSettings
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                validateForm={validateForm}
                submitCount={submitCount}
              />
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default BasicDetails;
