import React from 'react';
import { Card, MessageBar } from 'app/components';
import StatusCard from './StatusCard';
import { LightningCharge, CheckCircle, XCircle, PersonDash, Stopwatch, Pass, BoxSeam, Image, PauseFill } from 'react-bootstrap-icons';
import { ReactComponent as FulfillmentError } from 'app/assets/images/icons/fulfillment-error.svg';
import Skeleton from 'react-loading-skeleton';
import './index.scss';

const OrdersByStatus = ({ queryData = {} }) => {
  const {
    data: rawData = [],
    loading,
    error,
  } = queryData;

  const data = rawData.filter(status => !['Partial', 'Error', 'ReviewHold', 'AddressError'].includes(status.status_name));

  const getIcon = (status) => {
    console.log('status', status);
    switch (status) {
      case 'Open':
        return { icon: <Pass />, color: 'green' };
      case 'Canceled':
        return { icon: <XCircle />, color: 'red' };
      case 'ReadyForFulfillment':
        return { icon: <BoxSeam />, color: 'green' };
      case 'Fulfilled':
        return { icon: <CheckCircle />, color: 'green' };
      case 'InProduction':
        return { icon: <LightningCharge />, color: 'green' };
      case 'ImageError':
        return { icon: <Image />, color: 'red' };
      case 'FulfillmentError':
        return { icon: <FulfillmentError />, color: 'red' };
      case 'Hold':
        return { icon: <PauseFill />, color: 'yellow' };
      case 'Pending':
        return { icon: <Stopwatch />, color: 'yellow' };
      case 'CustomerCareHold':
        return { icon: <PersonDash />, color: 'yellow' };
      default:
        return { icon: <Pass />, color: 'green' };
    }
  }

  return (
    <Card className="status-cards-container">
      <div className="status-cards-header">
        Orders by Status
      </div>
      {data && data.length > 0 && (
        <div className="status-cards">
          {data.map((status, index) => {
            const icon = getIcon(status.status_name);
            return (
              <StatusCard
                key={index}
                label={status.status_name}
                icon={icon.icon}
                iconColor={icon.color}
                value={status.orders_count}
              />
            )
          })}
        </div>
      )}
      {!loading && !error && data && data.length === 0 && (
        <MessageBar color="yellow">
          No data available
        </MessageBar>
      )}
      {error && (
        <MessageBar color="yellow">
          An error occurred while fetching data
        </MessageBar>
      )}
      {loading && (
        <Skeleton height={100} count={2} borderRadius={15} />
      )}
    </Card>
  )
}

export default OrdersByStatus;